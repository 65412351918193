import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Message, User } from "../../../libs/user/types";

import { PlusButton, SubmitButton } from "../../Button";
import {
  useDeleteUserMessagesMutation,
  useSetUserMessagesMutation,
} from "../../../libs/user/api";
import { useEffect } from "react";
import { selectGetUserResult } from "../../../libs/user/selectors";
import { useParams } from "react-router-dom";
import {
  selectLastWeekMessages,
  selectMessages,
  updateMessage,
} from "../../../libs/user/slice";
import { NoteForm } from "./NoteForm";

type NoteListProps = {
  onClose: () => void;
};

type FormValues = {
  ptNote: string;
};
export const NoteList: React.FC<NoteListProps> = ({ onClose }) => {
  const { id: userId } = useParams();
  const user = useAppSelector((state) => selectGetUserResult(state, userId));
  const messages = useAppSelector(selectMessages);
  const lastWeekMessages = useAppSelector(selectLastWeekMessages);

  const [deleteNotes] = useDeleteUserMessagesMutation();
  const [setUserMessages, { data, error, isLoading }] =
    useSetUserMessagesMutation();

  const dispatch = useAppDispatch();

  const onLoad = ({
    messageIndex,
    ...message
  }: Message & { messageIndex: number }) => {
    const userNote = message.text?.user?.[0];
    const userNoteReadAt = userNote?.readAt;
    const userNoteMsg = userNote?.msg;
    if (!user || !user.id) {
      return;
    }
    if (userNoteReadAt) {
      return;
    }
    if (!userNoteMsg) {
      return;
    }
    const payload = {
      idUser: user.id,
      id: message.id,
      text: {
        ...message.text,
        user: [
          {
            ...userNote,
            readAt: Date.parse(new Date().toISOString()),
          },
        ],
      },
    };
    setUserMessages(payload)
      .unwrap()
      .then(() => {
        dispatch(updateMessage({ messageIndex, ...payload }));
      });
  };

  useEffect(() => {
    messages?.forEach((message, idx) => {
      onLoad({ ...message, messageIndex: idx });
    });
    lastWeekMessages?.forEach((message, idx) => {
      deleteNotes({ id: message.id });
    });
  }, []);

  return (
    <div className="flex flex-col items-center pb-4 overflow-y-scroll h-[80vh]">
      {messages.map((message, idx) => (
        <NoteForm
          key={idx}
          onClose={onClose}
          message={message}
          messageIndex={idx}
        />
      ))}
      {messages.length < 4 ? (
        <PlusButton
          onClick={() => {
            dispatch(
              updateMessage({
                messageIndex: messages.length,
                id: undefined,
                idUser: user?.id || 0,
                text: {
                  pt: [
                    {
                      msg: "",
                      createdAt: Date.parse(new Date().toISOString()),
                    },
                  ],
                },
              })
            );
          }}
          variant={"default"}
        >
          Aggiungi nota
        </PlusButton>
      ) : (
        <div className="">
          <p className=" text-sm">Hai raggiunto il limite di note</p>
        </div>
      )}
    </div>
  );
};
